import React from "react";
import Header from "../../components/ptBr/Header";
import Footer from "../../components/ptBr/Footer";
import Whatsapp from "../../components/ptBr/util/Whatsapp";
import vendoEstrelas from '../../assets/vendo-estrelas.gif';

const NotFound = () => {
    return (
        <>
            <div className="flex flex-col h-screen text-center">
                <Header />
                <Whatsapp />
                <div className="flex flex-col justify-center items-center min-h-screen p-4">
                    <div className="text-center">
                        <img 
                        src={vendoEstrelas} 
                        alt="Vendo Estrelas" 
                        className="w-full md:w-1/3 lg:w-1/4 mx-auto block mb-6"
                        />
                        <h1 className="text-6xl md:text-8xl font-bold text-gray-700">404</h1>
                        <p className="text-xl md:text-2xl text-gray-600 mt-4">Oops! Página não encontrada.</p>
                        <p className="text-gray-500 mt-2">
                        A página que você está procurando pode ter sido movida ou não existe mais.
                        </p>
                        <a
                        href="/"
                        className="mt-6 px-8 py-3 bg-blue-500 text-white text-lg md:text-xl rounded-lg shadow hover:bg-blue-600 transition inline-block"
                        >
                        Voltar para o Início
                        </a>
                    </div>
                    </div>
                <Footer />
            </div>
        </>
    );
};

export default NotFound;