import crm from '../../../assets/crm.webp'
import crmHawx from '../../../assets/crm-hawx.webp'
import eduardomeneghel from '../../../assets/eduardomeneghel.webp'
import notion from '../../../assets/notion.webp'
import notionLogo from '../../../assets/notion-logo.webp'
import logoGoogleMeuNegocio from '../../../assets/logo-google-meu-negocio.webp'
import googleMeuNegocio from '../../../assets/google-meu-negocio.webp'
import fotoDeQualidade from '../../../assets/foto-de-qualidade.webp'
import foto360 from '../../../assets/fotos360.webp'

export const blogPosts = [
  {
    id: "ventajas-del-crm-para-empresas",
    url: "/es/blog/ventajas-del-crm-para-empresas",
    title: "Ventajas del CRM para Empresas",
    description: "Explora cómo el CRM puede optimizar procesos, mejorar las relaciones con los clientes y aumentar los resultados de tu empresa.",
    coverImage: crm,
    content: [
      {
        order: 0,
        type: "header",
        content: "¿Qué es un CRM y por qué es esencial?"
      },
      {
        order: 1,
        type: "text",
        content: "CRM (Customer Relationship Management, o Gestión de Relaciones con el Cliente) es una estrategia que centraliza las interacciones con los clientes, ayudando a las empresas a ofrecer un servicio más eficiente y personalizado. Más que un software, el CRM combina procesos, tecnología y estrategias para retener y atraer clientes."
      },
      {
        order: 2,
        type: "imageFull",
        content: crmHawx,
      },
      {
        order: 3,
        type: "header",
        content: "¿Qué hace?"
      },
      {
        order: 4,
        type: "text",
        content: "El CRM integra ventas, marketing y atención al cliente en una sola plataforma, facilitando el acceso a la información y permitiendo un seguimiento más eficiente del recorrido del cliente. Además, el sistema optimiza procesos, centraliza datos y mejora la experiencia en cada etapa de la relación con el cliente."
      },
      {
        order: 5,
        type: "header",
        content: "Principales Ventajas del CRM"
      },
      {
        order: 6,
        type: "image1/2",
        content: crm,
      },
      {
        order: 7,
        type: "list",
        content: [
          "Centraliza la información y el historial de los clientes, permitiendo un acceso rápido y organizado a los datos.",
          "Automatiza procesos operativos, como el envío de correos electrónicos y recordatorios de seguimiento.",
          "Aumenta la productividad del equipo de ventas al reducir tareas repetitivas.",
          "Facilita el seguimiento de métricas y el rendimiento con informes detallados.",
          "Ayuda a fidelizar a los clientes ofreciendo un servicio personalizado y consistente.",
          "Permite el seguimiento en tiempo real de cada oportunidad de venta, priorizando los leads más valiosos.",
          "Fortalecer las relaciones con los clientes al gestionar todo el ciclo de vida, desde el primer contacto hasta el postventa."
        ]
      },
      {
        order: 8,
        type: "header",
        content: "Impacto en las Relaciones con los Clientes"
      },
      {
        order: 9,
        type: "text",
        content: "Con un CRM, tu empresa puede crear una experiencia más conectada y relevante para los clientes. El historial detallado de interacciones ayuda a comprender las necesidades de cada cliente y ofrecer soluciones personalizadas en el momento adecuado, fortaleciendo los lazos y aumentando la retención.\nAlgunos CRMs permiten la integración con correo electrónico y WhatsApp, optimizando la comunicación y el marketing. Estas herramientas ayudan a mantener la marca presente, permitiendo el envío de mensajes estratégicos que refuerzan las relaciones con los clientes. Esto permite realizar acciones de preventa, venta y postventa de manera eficiente, alineando toda la experiencia en una sola plataforma."
      },
      {
        order: 10,
        type: "header",
        content: "Aumentando la Eficiencia Operativa"
      },
      {
        order: 11,
        type: "text",
        content: "El CRM automatiza tareas rutinarias, como el envío de correos electrónicos de seguimiento, programaciones y actualizaciones de estado. Esto reduce el tiempo dedicado a actividades repetitivas, permitiendo que tu equipo se concentre en lo que realmente importa: cerrar negocios."
      },
      {
        order: 12,
        type: "header",
        content: "Conclusión"
      },
      {
        order: 13,
        type: "text",
        content: "Invertir en un CRM es un paso estratégico para cualquier empresa que desee crecer de manera sostenible. Con beneficios que van desde la organización interna hasta la satisfacción del cliente, el CRM es una herramienta indispensable en el mercado competitivo actual."
      }
    ],
    uploadDate: "2024-12-29T12:00:00Z",
    author: {
      name: "Eduardo Pedro Meneghel",
      photo: eduardomeneghel
    }
  },
  {
    id: "como-evitar-la-perdida-de-datos-usando-notion",
    url: "/es/blog/como-evitar-la-perdida-de-datos-usando-notion",
    title: "Cómo Evitar la Pérdida de Datos, Facilitar el Entrenamiento y Minimizar el Impacto de las Salidas de Empleados Usando Notion",
    description: "Aprende a usar Notion para organizar información, optimizar el entrenamiento y manejar la salida de empleados sin comprometer la eficiencia de tu negocio.",
    coverImage: notion,
    content: [
      {
        order: 0,
        type: "header",
        content: "Introducción: La Importancia de una Gestión Eficiente del Conocimiento"
      },
      {
        order: 1,
        type: "text",
        content: "Las empresas enfrentan desafíos como la pérdida de información, alta rotación y dificultades en la capacitación de nuevos empleados. Estos problemas pueden llevar a costos elevados, interrupciones en los procesos y baja productividad. Afortunadamente, herramientas como Notion pueden ayudar a superar estas barreras al centralizar datos, estandarizar capacitaciones y simplificar la transición de responsabilidades."
      },
      {
        order: 2,
        type: "image1/2",
        content: notionLogo
      },
      {
        order: 3,
        type: "header",
        content: "1. Prevención de la Pérdida de Datos"
      },
      {
        order: 4,
        type: "text",
        content: "La pérdida de datos ocurre frecuentemente cuando la información está dispersa en múltiples plataformas o limitada al conocimiento de unos pocos empleados. En Notion, puedes crear un sistema unificado que almacene documentos importantes, manuales de procedimientos y datos críticos de manera accesible para todo el equipo."
      },
      {
        order: 5,
        type: "list",
        content: [
          "Organiza un 'Centro de Conocimiento' con enlaces y documentos categorizados.",
          "Crea páginas para cada departamento, con subpáginas dedicadas a proyectos, informes y procedimientos.",
          "Implementa bases de datos con campos personalizables para almacenar contactos, contratos y registros de clientes."
        ]
      },
      {
        order: 6,
        type: "header",
        content: "2. Facilitando el Entrenamiento y la Incorporación"
      },
      {
        order: 7,
        type: "text",
        content: "El entrenamiento de nuevos empleados puede simplificarse con plantillas reutilizables y procesos documentados en Notion. Esto reduce el tiempo necesario para capacitar a nuevos empleados y mejora la consistencia en el aprendizaje."
      },
      {
        order: 8,
        type: "list",
        content: [
          "Crea páginas de incorporación específicas para cada rol, con instrucciones detalladas.",
          "Agrega listas de verificación para seguir el progreso de las capacitaciones.",
          "Incluye videos, presentaciones y enlaces útiles directamente en las páginas de entrenamiento."
        ]
      },
      {
        order: 9,
        type: "header",
        content: "3. Manejando la Salida de Empleados"
      },
      {
        order: 10,
        type: "text",
        content: "La salida de un empleado puede afectar negativamente el flujo de trabajo, especialmente si el conocimiento no se transfiere adecuadamente. En Notion, puedes crear sistemas de transición que aseguren que la información crucial esté documentada y compartida con el equipo."
      },
      {
        order: 11,
        type: "list",
        content: [
          "Implementa una página de transferencia de conocimiento con detalles de proyectos y contactos.",
          "Documenta flujos de trabajo y procesos críticos para facilitar la continuidad.",
          "Usa funciones de asignación de tareas para reorganizar responsabilidades durante la transición."
        ]
      },
      {
        order: 12,
        type: "header",
        content: "Beneficios de Usar Notion en la Gestión Empresarial"
      },
      {
        order: 13,
        type: "text",
        content: "Al usar Notion, tu empresa logra una gestión más eficiente, con datos centralizados, procesos documentados y capacitaciones optimizadas. Esto no solo reduce los costos operativos, sino que también mejora la resiliencia del negocio, permitiendo que tu equipo se adapte rápidamente a los cambios."
      },
      {
        order: 14,
        type: "header",
        content: "Conclusión"
      },
      {
        order: 15,
        type: "text",
        content: "Implementar Notion como una herramienta central para tu empresa es una decisión estratégica. Te permite prevenir la pérdida de datos, facilitar el entrenamiento y minimizar el impacto de la rotación. Al crear sistemas organizados y accesibles, transformas la gestión del conocimiento en una ventaja competitiva, asegurando la continuidad del negocio y el éxito a largo plazo."
      }
    ],
    uploadDate: "2025-01-18T12:00:00Z",
    author: {
      name: "Eduardo Pedro Meneghel",
      photo: eduardomeneghel
    }
  },
  {
    id: "como-mejorar-tu-relevancia-en-google-mi-negocio",
    url: "/es/blog/como-mejorar-tu-relevancia-en-google-mi-negocio",
    title: "Cómo Mejorar tu Relevancia en Google Mi Negocio y Atraer Más Clientes",
    description: "Descubre estrategias comprobadas para optimizar tu perfil en Google Mi Negocio, aumentar tu visibilidad y ganar más clientes.",
    coverImage: logoGoogleMeuNegocio,
    content: [
      {
        order: 0,
        type: "header",
        content: "¿Por qué es Esencial Google Mi Negocio para tu Empresa?"
      },
      {
        order: 1,
        type: "text",
        content: "Google Mi Negocio (GMB) es una herramienta indispensable para las empresas que desean aumentar su visibilidad en línea. No solo mejora tu presencia en los resultados de búsqueda locales, sino que también fortalece la credibilidad de tu marca y atrae clientes potenciales. Un perfil bien optimizado puede ser la diferencia entre ser encontrado o pasar desapercibido."
      },
      {
        order: 2,
        type: "image1/2",
        content: googleMeuNegocio,
      },
      {
        order: 3,
        type: "header",
        content: "1. Completa Toda la Información de tu Perfil"
      },
      {
        order: 4,
        type: "text",
        content: "Un perfil completo es el primer paso para destacar. Asegúrate de incluir la siguiente información:\n- Nombre de la empresa y área de especialización (ejemplo: Hawx Soluciones - Desarrollo de Software).\n- Dirección completa y actualizada.\n- Teléfono y correo electrónico para contacto.\n- Horario de atención, incluyendo feriados y horarios especiales.\n- Sitio web y redes sociales, si están disponibles."
      },
      {
        order: 5,
        type: "highlight",
        content: "Consejo: Usa palabras clave relevantes en el nombre de la empresa y la descripción para mejorar el SEO local."
      },
      {
        order: 6,
        type: "header",
        content: "2. Agrega Fotos de Alta Calidad"
      },
      {
        order: 7,
        type: "text",
        content: "Las fotos profesionales aumentan el atractivo de tu perfil y generan una buena primera impresión. Incluye:\n- Fachada del establecimiento para facilitar la ubicación.\n- Ambiente interior y exterior.\n- Equipo para generar conexión y confianza.\n- Productos y servicios ofrecidos."
      },
      {
        order: 8,
        type: "image1/2",
        content: fotoDeQualidade,
      },
      {
        order: 9,
        type: "header",
        content: "3. Registra Productos y Servicios"
      },
      {
        order: 10,
        type: "text",
        content: "La función de productos y servicios en GMB ayuda a los clientes a entender lo que ofreces. Para maximizar su impacto:\n- Inserta descripciones detalladas con palabras clave relevantes.\n- Agrega precios, si es aplicable.\n- Incluye imágenes atractivas para cada artículo."
      },
      {
        order: 11,
        type: "header",
        content: "4. Usa Fotos 360° para una Experiencia Inmersiva"
      },
      {
        order: 12,
        type: "text",
        content: "Las fotos 360° ofrecen una vista completa de tu espacio, aumentando el compromiso y la confianza de los clientes. Esta función es ideal para:\n- Restaurantes, gimnasios y salones de belleza.\n- Oficinas y espacios de coworking.\n- Hoteles y posadas."
      },
      {
        order: 13,
        type: "imageFull",
        content: foto360,
      },
      {
        order: 14,
        type: "header",
        content: "5. Mantén tu Perfil Actualizado"
      },
      {
        order: 15,
        type: "text",
        content: "Un perfil desactualizado puede alejar a los clientes y dañar tu credibilidad. Practica:\n- Publicar publicaciones con novedades, eventos y ofertas.\n- Informar cambios en el horario de atención.\n- Actualizar imágenes y descripciones regularmente."
      },
      {
        order: 16,
        type: "header",
        content: "6. Ofrece Promociones y Novedades"
      },
      {
        order: 17,
        type: "text",
        content: "Las promociones son una excelente manera de atraer clientes y aumentar el compromiso. Usa GMB para:\n- Crear cupones de descuento exclusivos.\n- Anunciar lanzamientos de productos o servicios.\n- Promover eventos especiales y ofertas temporales."
      },
      {
        order: 18,
        type: "header",
        content: "7. Incentiva las Reseñas y Responde a Todas"
      },
      {
        order: 19,
        type: "text",
        content: "Las reseñas positivas mejoran tu clasificación y credibilidad. Para incentivarlas:\n- Pide comentarios a clientes satisfechos.\n- ⭐⭐⭐⭐⭐\n- Envía enlaces directos para reseñas a través de WhatsApp o correo electrónico.\n- Responde a todas las reseñas, ya sean positivas o negativas."
      },
      {
        order: 20,
        type: "highlight",
        content: "Consejo: Responder a las reseñas muestra que valoras los comentarios de los clientes y te importa su experiencia."
      },
      {
        order: 21,
        type: "header",
        content: "Conclusión"
      },
      {
        order: 22,
        type: "text",
        content: "Optimizar tu perfil en Google Mi Negocio es una estrategia esencial para cualquier empresa que desee aumentar su visibilidad y atraer más clientes. Al seguir estos consejos, no solo mejoras tu clasificación, sino que también creas una experiencia positiva para tus clientes, fortaleciendo tu marca e impulsando tus ventas."
      }
    ],
    uploadDate: "2025-02-02T12:00:00Z",
    author: {
      name: "Eduardo Pedro Meneghel",
      photo: eduardomeneghel
    }
  }
]