import React from "react";
import { Link } from "react-router-dom";
import Header from "../../components/en/Header";
import Footer from "../../components/en/Footer";
import Whatsapp from "../../components/en/util/Whatsapp";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import crm1 from '../../assets/crm1.webp';
import crm2 from '../../assets/crm2.webp';
import crm3 from '../../assets/crm3.webp';
import crm4 from '../../assets/crm4.webp';
import crm5 from '../../assets/crm5.webp';
import crm6 from '../../assets/crm6.webp';

const products = [
  {
    id: 1,
    name: "Business CRM",
    description: "Our CRM automates processes, organizes data, and tracks opportunities so you can sell more with less effort. With data analysis and closer relationships, your decisions become more strategic and effective.",
    price: "$49.90/month",
    images: [crm1, crm2, crm3, crm4, crm5, crm6],
    whatsappLink: "https://wa.me/5548999631298?text=Hello%2C%20I%20want%20to%20know%20more%20about%20the%20Professional%20CRM!"
  }
];

function Products() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000
  };

  return (
    <>
      <Header />
      <section className="bg-gray-100 pt-24">
        <div className="max-w-7xl mx-auto py-12 px-8">
          <h1 className="text-4xl font-bold lg:text-center" id="products">Discover Our Products</h1>
          <p className="text-lg text-gray-600 mt-4 lg:text-center">Innovative solutions to help your business grow!</p>
          <p className="text-lg text-gray-600 mt-4 lg:text-center">
            Need a customized solution for your business? <Link to="/#services" className="text-green-700 hover:underline">Check out our services.</Link>
          </p>
        </div>
      </section>
      <div className="max-w-7xl mx-auto py-16 px-6">
        {products.map((product) => (
          <div key={product.id} className="flex flex-col md:flex-row bg-white border rounded-lg overflow-hidden p-6 mb-10">
            <div className="w-full md:w-1/3">
              <Slider {...settings}>
                {product.images.map((image, index) => (
                  <div key={index}>
                    <img
                      src={image}
                      alt={`${product.name} - Image ${index + 1}`}
                      className="w-full object-cover rounded-lg"
                    />
                  </div>
                ))}
              </Slider>
            </div>
            <div className="md:ml-6 mt-4 md:mt-0 text-left">
              <h2 className="text-2xl font-semibold mb-2">{product.name}</h2>
              <p className="text-gray-600 mb-4">{product.description}</p>
              <p className="text-lg font-bold text-green-700">{product.price}</p>
              <a
                href={product.whatsappLink}
                className="inline-block mt-4 bg-green-700 text-white px-6 py-3 rounded-lg shadow hover:bg-green-800 transition"
                target="_blank"
              >
                Contact Us on WhatsApp
              </a>
            </div>
          </div>
        ))}
      </div>
      <Whatsapp />
      <Footer />
    </>
  );
}

export default Products;
