import React from "react";

const Vision = () => {
  return (
    <>
      <div className="bg-pattern flex items-center xl:py-32 py-16">
        <div className="text-black w-3/4 mx-auto">
          <p className="xl:text-6xl text-4xl font-bold text-white text-center">Visión</p>
          <p className="text-2xl mt-12 text-justify text-white">
            Nuestra visión en Hawx Soluciones es convertirnos en líderes reconocidos en el sector tecnológico, siendo la primera opción para las empresas que buscan soluciones digitales innovadoras y confiables. Queremos expandir nuestra presencia a nivel global, manteniendo nuestro compromiso con la excelencia, la creatividad y la satisfacción del cliente.
          </p>
        </div>
      </div>
    </>
  );
};

export default Vision;
