import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import logo from '../../assets/logo-white.webp';
import brFlag from '../../assets/br-flag.webp';
import usFlag from '../../assets/us-flag.webp';
import esFlag from '../../assets/es-flag.webp';

const Header = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  
  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const scrollToServices = () => {
    const servicesSection = document.getElementById("services");
    if (servicesSection) {
      servicesSection.scrollIntoView({ behavior: "smooth" });
    } else {
      console.warn("The services section was not found.");
    }
  };

  useEffect(() => {
    if (window.location.hash === "#services") {
      scrollToServices();
    }
  }, []);

  return (
    <header className="block px-8 bg-pattern z-50 w-full absolute">
      <nav className="w-11/12 mx-auto py-6 flex justify-between items-center">
        <div>
          <a href="https://hawx.com.br/en/" className="inline-block">
            <img src={logo} alt="Logo of a white bird, representing a falcon with wings spread, in a minimalist style, on a circular blue background. Next to the logo, the word 'Hawx' is written, with 'Solutions' below." className="w-32 xl:w-44" />
          </a>
        </div>
        <div className="md:hidden">
          <button
            className="text-white hover:text-black focus:outline-none p-2"
            onClick={toggleMobileMenu}
            aria-label={isMobileMenuOpen ? "Close menu" : "Open menu"}
          >
            {isMobileMenuOpen ? (
              <FontAwesomeIcon icon={faTimes} className="text-xl" />
            ) : (
              <FontAwesomeIcon icon={faBars} className="text-xl" />
            )}
          </button>
        </div>
        <ul className="hidden md:flex space-x-4 items-center">
          <li>
            <a href="https://hawx.com.br/en/" className="text-white hover:text-black font-semibold">
              Home
            </a>
          </li>
          <li>
            <a
              href="https://hawx.com.br/en/#services"
              className="text-white hover:text-black font-semibold"
            >
              Services
            </a>
          </li>
          <li>
            <a href="https://hawx.com.br/en/products" className="text-white hover:text-black font-semibold">
              Products
            </a>
          </li>
          <li>
            <a href="https://hawx.com.br/en/blog" className="text-white hover:text-black font-semibold">
              Blog
            </a>
          </li>
          <li>
            <a href="https://hawx.com.br/en/about-us" className="text-white hover:text-black font-semibold">
              About Us
            </a>
          </li>
          <li>
            <a
              href="https://hawx.com.br/enhttps://api.whatsapp.com/send?phone=5548999631298"
              target="_blank"
              rel="noopener noreferrer"
              className="text-white hover:text-black font-semibold"
            >
              Contact
            </a>
          </li>
          <li className="flex space-x-2">
            <a href="https://hawx.com.br/en/../" aria-label="Portuguese">
              <img src={brFlag} alt="Brazil Flag" className="w-6 h-6" />
            </a>
            <a href="https://hawx.com.br/en/../en" aria-label="English">
              <img src={usFlag} alt="USA Flag" className="w-6 h-6" />
            </a>
            <a href="https://hawx.com.br/en/../es" aria-label="Español">
              <img src={esFlag} alt="Spain Flag" className="w-6 h-6" />
            </a>
          </li>
        </ul>
        <div
          className={`fixed inset-0 bg-pattern z-40 transform ${isMobileMenuOpen ? "translate-x-0" : "translate-x-full"
            } transition-transform duration-300 ease-in-out`}
        >
          <button
            className="absolute top-6 right-6 text-white text-3xl focus:outline-none"
            onClick={toggleMobileMenu}
            aria-label="Close menu"
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>

          <ul className="flex flex-col items-center justify-center h-screen">
            <li className="mb-8">
              <a
                href="https://hawx.com.br/en/"
                className="text-white text-2xl hover:text-black block"
              >
                Home
              </a>
            </li>
            <li className="mb-8">
              <a
                href="https://hawx.com.br/en/#services"
                className="text-white text-2xl hover:text-black block"
                onClick={toggleMobileMenu}
              >
                Services
              </a>
            </li>
            <li className="mb-8">
              <a
                href="https://hawx.com.br/en/products"
                className="text-white text-2xl hover:text-black block"
              >
                Products
              </a>
            </li>
            <li className="mb-8">
              <a
                href="https://hawx.com.br/en/blog"
                className="text-white text-2xl hover:text-black block"
              >
                Blog
              </a>
            </li>
            <li className="mb-8">
              <a
                href="https://hawx.com.br/en/about-us"
                className="text-white text-2xl hover:text-black block"
              >
                About Us
              </a>
            </li>
            <li className="mb-8">
              <a
                href="https://api.whatsapp.com/send?phone=5548999631298"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white text-2xl hover:text-black block"
              >
                Contact
              </a>
            </li>
            <li className="mb-8 flex space-x-2">
              <a href="https://hawx.com.br/en/../" aria-label="Portuguese">
                <img src={brFlag} alt="Brazil Flag" className="w-6 h-6" />
              </a>
              <a href="https://hawx.com.br/en/../en" aria-label="English">
                <img src={usFlag} alt="USA Flag" className="w-6 h-6" />
              </a>
              <a href="https://hawx.com.br/en/../es" aria-label="Español">
                <img src={esFlag} alt="Spain Flag" className="w-6 h-6" />
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
};

export default Header;