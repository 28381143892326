import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { useDebounce } from "./util/useDebounce";
import ReactMarkdown from 'react-markdown';

function BlogGenerator({ blogPosts }) {
  const { id } = useParams();
  const [currentPost, setCurrentPost] = useState(null);
  const [recommendedPosts, setRecommendedPosts] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const debouncedSearchQuery = useDebounce(searchQuery, 500);

  useEffect(() => {
    if (id) {
      const post = blogPosts.find((item) => item.id === id);
      setCurrentPost(post);
      const otherPosts = blogPosts;
      const randomRecommendations = otherPosts
        .sort(() => 0.5 - Math.random())
        .slice(0, 3);
      setRecommendedPosts(randomRecommendations);
    } else {
      setCurrentPost(blogPosts[blogPosts.length - 1]);
      setRecommendedPosts(blogPosts.slice(0, 3));
    }
  }, [id, blogPosts]);

  useEffect(() => {
    if (debouncedSearchQuery.trim()) {
      const results = blogPosts.filter((item) =>
        item.title.toLowerCase().includes(debouncedSearchQuery.toLowerCase())
      );
      setSearchResults(results);
    } else {
      setSearchResults([]);
    }
  }, [debouncedSearchQuery, blogPosts]);

  return (
    <div className="max-w-7xl mx-auto p-6 grid grid-cols-1 md:grid-cols-4 gap-6 pt-32">
      {currentPost && (
        <div className="col-span-3 mt-8">
          <div className="flex mb-8">
            <img src={currentPost.coverImage} alt="Illustrative image of the topic covered" className="w-32 h-32" />
            <div className="block pl-4">
              <h1 className="text-3xl font-bold block">{currentPost.title}</h1>
              <h2 className="text-lg text-gray-600">{currentPost.description}</h2>
            </div>
          </div>
          {currentPost.content.map((content, index) => (
            <div key={index} className="mb-8">
              {content.type === "text" && (
                <ReactMarkdown className="prose">{content.content}</ReactMarkdown>
              )}
              {content.type === "imageFull" && <img src={content.content} alt={`Image ${index}`} className="w-full" />}
              {content.type === "image1/2" && <img src={content.content} alt={`Image ${index}`} className="w-1/2" />}
              {content.type === "image1/4" && <img src={content.content} alt={`Image ${index}`} className="w-1/4" />}
              {content.type === "header" && <h2 className="text-2xl font-bold mb-4">{content.content}</h2>}
              {content.type === "highlight" && (
                <div className="p-4 bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700">
                  <ReactMarkdown className="prose">{content.content}</ReactMarkdown>
                </div>
              )}
              {content.type === "list" && (
                <ul className="list-disc ml-5">
                  {content.content.map((item, i) => (
                    <li key={i}>
                      <ReactMarkdown className="prose">{item}</ReactMarkdown>
                    </li>
                  ))}
                </ul>
              )}
              {content.type === "code" && <pre className="bg-gray-800 text-white p-4 rounded">{content.content}</pre>}
              {content.type === "quote" && (
                <blockquote className="italic border-l-4 pl-4 border-gray-500">
                  <ReactMarkdown className="prose">{content.content}</ReactMarkdown>
                </blockquote>
              )}
              {content.type === "divider" && <hr className="my-8" />}
            </div>
          ))}
          <div className="flex items-center space-x-4 mb-8">
            <img
              src={currentPost.author.photo}
              alt={"Author's photo in circular format " + currentPost.author.name}
              className="w-10 h-10 rounded-full"
            />
            <div>
              <p className="font-semibold">{currentPost.author.name}</p>
              <p className="text-sm text-gray-500">Published on {new Date(currentPost.uploadDate).toLocaleDateString()}</p>
            </div>
          </div>
        </div>
      )}
      <aside className="space-y-6 mt-8">
        <input
          type="text"
          placeholder="Search posts..."
          className="w-full p-3 border border-gray-300 rounded-lg shadow-sm"
          onChange={(e) => setSearchQuery(e.target.value)}
          value={searchQuery}
        />

        {searchResults.length > 0 ? (
          <div>
            <p className="text-lg font-bold mb-4">Search Results</p>
            <ul className="list-disc">
              {searchResults.map((result, index) => (
                <li key={index} className="flex items-center space-x-4">
                  <img
                    src={result.coverImage}
                    alt={result.title}
                    className="w-16 h-16 rounded-lg"
                  />
                  <Link to={result.url} className="text-blue-600 hover:underline">
                    {result.title.length > 50 ? `${result.title.slice(0, 50)}...` : result.title}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        ) : (
          <div>
            <p className="text-lg font-bold mb-4">Recommendations</p>
            <ul className="space-y-4">
              {recommendedPosts.map((post, index) => (
                <li key={index} className="flex items-center space-x-4">
                  <img
                    src={post.coverImage}
                    alt={post.title}
                    className="w-16 h-16 rounded-lg"
                  />
                  <Link to={post.url} className="text-blue-600 hover:underline">
                    {post.title.length > 50 ? `${post.title.slice(0, 50)}...` : post.title}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        )}
      </aside>
    </div>
  );
}

export default BlogGenerator;