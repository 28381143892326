import React, { useState } from 'react';
import imagen1 from "../../../assets/1.webp";

const Logotipo = () => {
  return (
    <>
      <div className="flex flex-col-reverse xl:flex-row xl:pt-24 pb-12 mb-4">
        <div className="flex xl2:w-1/2 xl:w-10/12 z-10 xl:block justify-center xl:justify-end animation-image">
           <img src={imagen1} alt="Teléfono móvil flotando con un fondo azul circular. En la pantalla del teléfono, se muestra un sitio web." />
        </div>
        <div className="xl:w-1/2 z-10">
          <p className="text-xl font-medium mb-4 text-green-700">Sitio web</p>
          <p className="text-3xl xl:text-6xl mb-10 font-medium">Promociona tu negocio en línea</p>
          <p className="text-2xl mt-12 mb-16">Haz como otras empresas, ten tu propio sitio web y atrae nuevos clientes.</p>
        </div>
      </div>
    </>
  );
};

export default Logotipo;
