import React, { useState } from 'react';
import imagem1 from "../../../assets/1.webp";

const Logotipo = () => {
  return (
    <>
      <div className="flex flex-col-reverse xl:flex-row xl:pt-24 pb-12 mb-4">
        <div className="flex xl2:w-1/2 xl:w-10/12 z-10 xl:block justify-center xl:justify-end animation-image">
           <img src={imagem1} alt="Celular flutuando com um fundo azul circular. Na tela do celular, é exibido um site." />
        </div>
        <div className="xl:w-1/2 z-10">
          <p className="text-xl font-medium mb-4 text-green-700">Site</p>
          <p className="text-3xl xl:text-6xl mb-10 font-medium">Promova seu negócio online</p>
          <p className="text-2xl mt-12 mb-16">Faça como as outras empresas, tenha seu próprio site e atraia novos clientes.</p>
        </div>
      </div>
    </>
  );
};

export default Logotipo;
