import React from "react";

const About = () => {
  return (
    <div className="bg-pattern xl:pt-48 py-16 overflow-hidden">
      <div className="text-black w-3/4 mx-auto">
        <h1 className="xl:text-6xl text-3xl font-bold text-white text-center" aria-label="Sobre nosotros">
          Sobre nosotros
        </h1>
        <div className="mt-8 xl:mt-16 mb-16">
          <p className="text-2xl text-white text-justify" aria-label="Descripción sobre Eduardo y Hawx Soluciones">
            Soy Eduardo, graduado en Sistemas de Información y orgulloso fundador de Hawx Soluciones. Con un profundo interés en el emprendimiento desde el inicio de mi carrera, mi empresa representa el resultado de este deseo de innovación y excelencia en soluciones tecnológicas. Estamos comprometidos a ofrecer servicios de calidad y soluciones personalizadas para satisfacer las necesidades de nuestros clientes.
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;