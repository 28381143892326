import crm from '../../../assets/crm.webp'
import crmHawx from '../../../assets/crm-hawx.webp'
import eduardomeneghel from '../../../assets/eduardomeneghel.webp'
import notion from '../../../assets/notion.webp'
import notionLogo from '../../../assets/notion-logo.webp'
import logoGoogleMeuNegocio from '../../../assets/logo-google-meu-negocio.webp'
import googleMeuNegocio from '../../../assets/google-meu-negocio.webp'
import fotoDeQualidade from '../../../assets/foto-de-qualidade.webp'
import foto360 from '../../../assets/fotos360.webp'

export const blogPosts = [
  {
    id: "vantagens-do-crm-para-empresas",
    url: "/blog/vantagens-do-crm-para-empresas",
    title: "Vantagens do CRM para Empresas",
    description: "Explore como o CRM pode otimizar processos, melhorar o relacionamento com clientes e aumentar os resultados da sua empresa.",
    coverImage: crm,
    content: [
      {
        order: 0,
        type: "header",
        content: "O que é um CRM e por que ele é essencial?"
      },
      {
        order: 1,
        type: "text",
        content: "CRM (Customer Relationship Management, ou Gestão de Relacionamento com o Cliente) é uma estratégia que centraliza as interações com o cliente, auxiliando empresas a oferecerem um atendimento mais eficiente e personalizado. Mais do que apenas um software, o CRM une processos, tecnologia e estratégias voltadas para fidelizar e atrair clientes."
      },
      {
        order: 2,
        type: "imageFull",
        content: crmHawx,
      },
      {
        order: 3,
        type: "header",
        content: "O que ele faz?"
      },
      {
        order: 4,
        type: "text",
        content: "O CRM integra vendas, marketing e atendimento em uma única plataforma, facilitando o acesso às informações e permitindo um acompanhamento mais eficiente da jornada do cliente. Além disso, o sistema otimiza processos, centraliza dados e melhora a experiência em todas as etapas do relacionamento com o cliente."
      },
      {
        order: 5,
        type: "header",
        content: "Principais Vantagens do CRM"
      },
      {
        order: 6,
        type: "image1/2",
        content: crm,
      },
      {
        order: 7,
        type: "list",
        content: [
          "Centraliza informações e históricos de clientes, permitindo acesso rápido e organizado aos dados.",
          "Automatiza processos operacionais, como envio de e-mails e lembretes de follow-up.",
          "Aumenta a produtividade da equipe de vendas ao reduzir tarefas repetitivas.",
          "Facilita o acompanhamento de métricas e desempenho com relatórios detalhados.",
          "Ajuda a fidelizar clientes ao oferecer um atendimento personalizado e consistente.",
          "Permite acompanhar o progresso de cada oportunidade de venda em tempo real, priorizando os leads mais valiosos.",
          "Fortalece o relacionamento com clientes ao gerenciar todo o ciclo de vida, desde o primeiro contato até o pós-venda."
        ]
      },
      {
        order: 8,
        type: "header",
        content: "Impacto no Relacionamento com o Cliente"
      },
      {
        order: 9,
        type: "text",
        content: "Com um CRM, sua empresa pode criar uma experiência mais conectada e relevante para os clientes. O histórico detalhado de interações ajuda a entender as necessidades de cada cliente e a oferecer soluções personalizadas no momento certo, fortalecendo os laços e aumentando a fidelização.\nAlguns CRMs permitem integração com e-mail e WhatsApp, otimizando a comunicação e o marketing. Essas ferramentas ajudam a manter a marca presente, permitindo o envio de mensagens estratégicas que reforçam o relacionamento com os clientes. Com isso, é possível realizar ações de pré-venda, venda e pós-venda de forma eficiente, alinhando toda a experiência em uma única plataforma."
      },
      {
        order: 10,
        type: "header",
        content: "Aumentando a Eficiência Operacional"
      },
      {
        order: 11,
        type: "text",
        content: "O CRM automatiza tarefas rotineiras, como envio de e-mails de acompanhamento, agendamentos e atualizações de status. Isso reduz o tempo gasto em atividades repetitivas, permitindo que sua equipe se concentre no que realmente importa: fechar negócios."
      },
      {
        order: 12,
        type: "header",
        content: "Conclusão"
      },
      {
        order: 13,
        type: "text",
        content: "Investir em um CRM é um passo estratégico para qualquer empresa que deseja crescer de forma sustentável. Com benefícios que vão desde a organização interna até a satisfação do cliente, o CRM é uma ferramenta indispensável no mercado competitivo atual."
      }
    ],
    uploadDate: "2024-12-29T12:00:00Z",
    author: {
      name: "Eduardo Pedro Meneghel",
      photo: eduardomeneghel
    }
  },
  {
    id: "como-evitar-perda-de-dados-usando-notion",
    url: "/blog/como-evitar-perda-de-dados-usando-notion",
    title: "Como Evitar a Perda de Dados, Facilitar o Treinamento e Minimizar Impactos de Demissões Usando o Notion",
    description: "Aprenda a usar o Notion para organizar informações, otimizar treinamentos e lidar com a saída de funcionários sem comprometer a eficiência do seu negócio.",
    coverImage: notion,
    content: [
      {
        order: 0,
        type: "header",
        content: "Introdução: A Importância de uma Gestão de Conhecimento Eficiente"
      },
      {
        order: 1,
        type: "text",
        content: "Empresas enfrentam desafios como perda de informações, alta rotatividade e dificuldades com treinamento de novos colaboradores. Esses problemas podem levar a custos elevados, interrupções nos processos e baixa produtividade. Felizmente, ferramentas como o Notion podem ajudar a superar essas barreiras ao centralizar dados, padronizar treinamentos e simplificar a transição de responsabilidades."
      },
      {
        order: 2,
        type: "image1/2",
        content: notionLogo
      },
      {
        order: 3,
        type: "header",
        content: "1. Prevenindo a Perda de Dados"
      },
      {
        order: 4,
        type: "text",
        content: "A perda de dados ocorre frequentemente quando informações estão dispersas em diversas plataformas ou limitadas ao conhecimento de poucos colaboradores. No Notion, você pode criar um sistema unificado que armazene documentos importantes, manuais de procedimentos e dados críticos de forma acessível a todos da equipe."
      },
      {
        order: 5,
        type: "list",
        content: [
          "Organize uma 'Central de Conhecimento' com links e documentos categorizados.",
          "Crie páginas para cada departamento, com subpáginas dedicadas a projetos, relatórios e procedimentos.",
          "Implemente bases de dados com campos customizáveis para armazenar contatos, contratos e registros de clientes."
        ]
      },
      {
        order: 6,
        type: "header",
        content: "2. Facilitando Treinamento e Onboarding"
      },
      {
        order: 7,
        type: "text",
        content: "O treinamento de novos colaboradores pode ser simplificado com templates reutilizáveis e processos documentados no Notion. Isso reduz o tempo necessário para capacitar novos funcionários e melhora a consistência no aprendizado."
      },
      {
        order: 8,
        type: "list",
        content: [
          "Crie páginas de onboarding específicas para cada cargo, com instruções detalhadas.",
          "Adicione checklists para acompanhar o progresso dos treinamentos.",
          "Inclua vídeos, apresentações e links úteis diretamente nas páginas de treinamento."
        ]
      },
      {
        order: 9,
        type: "header",
        content: "3. Lidando com a Saída de Colaboradores"
      },
      {
        order: 10,
        type: "text",
        content: "A saída de um funcionário pode impactar negativamente o fluxo de trabalho, especialmente se o conhecimento não for adequadamente transferido. No Notion, é possível criar sistemas de transição que garantam que informações cruciais sejam documentadas e compartilhadas com a equipe."
      },
      {
        order: 11,
        type: "list",
        content: [
          "Implemente uma página de transferência de conhecimento, com detalhes de projetos e contatos.",
          "Documente fluxos de trabalho e processos críticos para facilitar a continuidade.",
          "Use funções de atribuição de tarefas para reorganizar responsabilidades durante a transição."
        ]
      },
      {
        order: 12,
        type: "header",
        content: "Benefícios de Usar o Notion na Gestão Empresarial"
      },
      {
        order: 13,
        type: "text",
        content: "Ao usar o Notion, sua empresa obtém uma gestão mais eficiente, com dados centralizados, processos documentados e treinamentos otimizados. Isso não apenas reduz os custos operacionais, mas também melhora a resiliência do negócio, permitindo que sua equipe se adapte rapidamente a mudanças."
      },
      {
        order: 14,
        type: "header",
        content: "Conclusão"
      },
      {
        order: 15,
        type: "text",
        content: "Implementar o Notion como ferramenta central da sua empresa é uma decisão estratégica. Ele permite prevenir a perda de dados, facilitar o treinamento e minimizar os impactos da rotatividade. Ao criar sistemas organizados e acessíveis, você transforma a gestão do conhecimento em uma vantagem competitiva, garantindo a continuidade do negócio e o sucesso a longo prazo."
      }
    ],
    uploadDate: "2025-01-18T12:00:00Z",
    author: {
      name: "Eduardo Pedro Meneghel",
      photo: eduardomeneghel
    }
  },
  {
    id: "como-melhorar-sua-relevancia-no-google-meu-negocio",
    url: "/blog/como-melhorar-sua-relevancia-no-google-meu-negocio",
    title: "Como Melhorar Sua Relevância no Google Meu Negócio e Atrair Mais Clientes",
    description: "Descubra estratégias comprovadas para otimizar seu perfil no Google Meu Negócio, aumentar sua visibilidade e conquistar mais clientes.",
    coverImage: logoGoogleMeuNegocio,
    content: [
      {
        order: 0,
        type: "header",
        content: "Por que o Google Meu Negócio é Essencial para Sua Empresa?"
      },
      {
        order: 1,
        type: "text",
        content: "O Google Meu Negócio (GMB) é uma ferramenta indispensável para empresas que desejam aumentar sua visibilidade online. Ele não apenas melhora sua presença nos resultados de busca locais, mas também fortalece a credibilidade da sua marca e atrai clientes potenciais. Um perfil bem otimizado pode ser o diferencial entre ser encontrado ou passar despercebido."
      },
      {
        order: 2,
        type: "image1/2",
        content: googleMeuNegocio,
      },
      {
        order: 3,
        type: "header",
        content: "1. Complete Todos os Dados do Seu Perfil"
      },
      {
        order: 4,
        type: "text",
        content: "Um perfil completo é o primeiro passo para se destacar. Certifique-se de incluir as seguintes informações:\n- Nome da empresa e área de atuação (exemplo: Hawx Soluções - Desenvolvimento de Software).\n- Endereço completo e atualizado.\n- Telefone e e-mail para contato.\n- Horário de funcionamento, incluindo feriados e horários especiais.\n- Site e redes sociais, se disponíveis."
      },
      {
        order: 5,
        type: "highlight",
        content: "Dica: Utilize palavras-chave relevantes no nome da empresa e na descrição para melhorar o SEO local."
      },
      {
        order: 6,
        type: "header",
        content: "2. Adicione Fotos de Alta Qualidade"
      },
      {
        order: 7,
        type: "text",
        content: "Fotos profissionais aumentam a atratividade do seu perfil e geram uma boa primeira impressão. Inclua:\n- Fachada do estabelecimento para facilitar a localização.\n- Ambiente interno e externo.\n- Equipe para gerar conexão e confiança.\n- Produtos e serviços oferecidos."
      },
      {
        order: 8,
        type: "image1/2",
        content: fotoDeQualidade,
      },
      {
        order: 9,
        type: "header",
        content: "3. Cadastre Produtos e Serviços"
      },
      {
        order: 10,
        type: "text",
        content: "A funcionalidade de produtos e serviços no GMB ajuda os clientes a entenderem o que você oferece. Para maximizar seu impacto:\n- Insira descrições detalhadas com palavras-chave relevantes.\n- Adicione preços, se aplicável.\n- Inclua imagens atrativas para cada item."
      },
      {
        order: 11,
        type: "header",
        content: "4. Utilize Fotos 360° para uma Experiência Imersiva"
      },
      {
        order: 12,
        type: "text",
        content: "Fotos 360° oferecem uma visão completa do seu espaço, aumentando o engajamento e a confiança dos clientes. Essa funcionalidade é ideal para:\n- Restaurantes, academias e salões de beleza.\n- Escritórios e espaços de coworking.\n- Hotéis e pousadas."
      },
      {
        order: 13,
        type: "imageFull",
        content: foto360,
      },
      {
        order: 14,
        type: "header",
        content: "5. Mantenha o Perfil Atualizado"
      },
      {
        order: 15,
        type: "text",
        content: "Um perfil desatualizado pode afastar clientes e prejudicar sua credibilidade. Pratique:\n- Publicar posts com novidades, eventos e ofertas.\n- Informar mudanças no horário de funcionamento.\n- Atualizar imagens e descrições regularmente."
      },
      {
        order: 16,
        type: "header",
        content: "6. Ofereça Promoções e Novidades"
      },
      {
        order: 17,
        type: "text",
        content: "Promoções são uma ótima maneira de atrair clientes e aumentar o engajamento. Use o GMB para:\n- Criar cupons de desconto exclusivos.\n- Anunciar lançamentos de produtos ou serviços.\n- Divulgar eventos especiais e ofertas sazonais."
      },
      {
        order: 18,
        type: "header",
        content: "7. Incentive Avaliações e Responda a Todas"
      },
      {
        order: 19,
        type: "text",
        content: "Avaliações positivas melhoram seu ranqueamento e credibilidade. Para incentivá-las:\n- Peça feedback de clientes satisfeitos.\n- ⭐⭐⭐⭐⭐\n- Envie links diretos para avaliação via WhatsApp ou e-mail.\n- Responda a todas as avaliações, sejam positivas ou negativas."
      },
      {
        order: 20,
        type: "highlight",
        content: "Dica: Responder a avaliações mostra que você valoriza a opinião dos clientes e se importa com a experiência deles."
      },
      {
        order: 21,
        type: "header",
        content: "Conclusão"
      },
      {
        order: 22,
        type: "text",
        content: "Otimizar seu perfil no Google Meu Negócio é uma estratégia essencial para qualquer empresa que deseja aumentar sua visibilidade e atrair mais clientes. Ao seguir essas dicas, você não apenas melhora seu ranqueamento, mas também cria uma experiência positiva para seus clientes, fortalecendo sua marca e impulsionando suas vendas."
      }
    ],
    uploadDate: "2025-02-02T12:00:00Z",
    author: {
      name: "Eduardo Pedro Meneghel",
      photo: eduardomeneghel
    }
  }
]