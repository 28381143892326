import React from "react";
import Header from "../../components/es/Header";
import Footer from "../../components/es/Footer";
import About from "../../components/es/about/About";
import Mission from "../../components/es/about/Mission";
import Vision from "../../components/es/about/Vision";
import Values from "../../components/es/about/Values";
import Whatsapp from "../../components/es/util/Whatsapp";
const AboutUs = () => {
    return (
        <>
            <Header />
            <About />
            <Mission />
            <Vision />
            <Values />
            <Whatsapp />
            <Footer />
        </>
    );
};

export default AboutUs;
