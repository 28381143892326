import React from "react";

const Mission = () => {
  return (
    <>
      <div className="bg-gray-50 flex items-center xl:py-32 py-16">
        <div className="text-black w-3/4 mx-auto">
          <p className="xl:text-6xl text-4xl font-bold text-center">Mission</p>
          <p className="text-2xl mt-12 text-justify">
            Our mission at Hawx Solutions is to turn ideas into digital reality through innovative and customized technological solutions. We are committed to deeply understanding our clients' needs and delivering high-quality products and services that drive their businesses to success. We continuously strive to push boundaries, learn, and evolve, creating a lasting positive impact in the digital world.
          </p>
        </div>
      </div>
    </>
  );
};

export default Mission;
