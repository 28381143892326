import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "../../components/ptBr/Header";
import Footer from "../../components/ptBr/Footer";
import Whatsapp from "../../components/ptBr/util/Whatsapp";
import { useDebounce } from "../../components/ptBr/util/useDebounce";
import { blogPosts } from "../../components/ptBr/data/blogPosts";

function Blog() {
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const debouncedSearchQuery = useDebounce(searchQuery, 500);

  useEffect(() => {
    if (debouncedSearchQuery.trim()) {
      const results = blogPosts.filter((item) =>
        item.title.toLowerCase().includes(debouncedSearchQuery.toLowerCase())
      );
      setSearchResults(results);
    } else {
      setSearchResults(blogPosts);
    }
  }, [debouncedSearchQuery]);

  return (
    <>
      <Header />
      <section className="bg-gray-100 pt-24">
        <div className="max-w-7xl mx-auto py-12 px-8">
          <h1 className="text-4xl font-bold lg:text-center" id="blog">Blog</h1>
          <p className="text-lg text-gray-600 mt-4 lg:text-center">
            Bem-vindo ao nosso blog! Aqui compartilhamos artigos, dicas e insights sobre temas variados para mantê-lo informado e inspirado.
          </p>
          <p className="text-lg text-gray-600 mt-4 lg:text-center">
            Explore nossas publicações e descubra as últimas tendências e novidades do mercado.
          </p>
        </div>
      </section>
      <div className="max-w-7xl mx-auto py-16 px-6">
        <div className="mb-6">
          <input
            type="text"
            placeholder="Buscar posts..."
            className="w-full p-3 border border-gray-300 rounded-lg shadow-sm"
            onChange={(e) => setSearchQuery(e.target.value)}
            value={searchQuery}
          />
        </div>
        <h2 className="text-3xl font-bold mb-6">Todos os Posts</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {searchResults.length === 0 ? (
            <p className="text-gray-600">Nenhum post encontrado.</p>
          ) : (
            searchResults.map((post) => (
              <div key={post.id} className="devsite-card border rounded-lg overflow-hidden">
                <Link to={post.url} className="block">
                  <img
                    src={post.coverImage}
                    alt={post.title}
                    className="w-full h-auto object-cover"
                  />
                </Link>
                <div className="p-4">
                  <h3 className="text-xl font-semibold mb-2">
                    <Link to={post.url} className="text-black hover:underline">
                      {post.title}
                    </Link>
                  </h3>
                  <p className="text-gray-600 mb-2">{post.description}</p>
                  <div className="flex items-center space-x-2 text-sm text-gray-500">
                    {post.author.photo && (
                      <img src={post.author.photo} alt={post.author.name} className="w-6 h-6 rounded-full" />
                    )}
                    <span>
                      {post.author.name} - {new Date(post.uploadDate).toLocaleDateString("pt-BR")}
                    </span>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
      <Whatsapp />
      <Footer />
    </>
  );
}

export default Blog;
