import crm from '../../../assets/crm.webp'
import crmHawx from '../../../assets/crm-hawx.webp'
import eduardomeneghel from '../../../assets/eduardomeneghel.webp'
import notion from '../../../assets/notion.webp'
import notionLogo from '../../../assets/notion-logo.webp'
import logoGoogleMeuNegocio from '../../../assets/logo-google-meu-negocio.webp'
import googleMeuNegocio from '../../../assets/google-meu-negocio.webp'
import fotoDeQualidade from '../../../assets/foto-de-qualidade.webp'
import foto360 from '../../../assets/fotos360.webp'

export const blogPosts = [
  {
    id: "advantages-of-crm-for-businesses",
    url: "/en/blog/advantages-of-crm-for-businesses",
    title: "Advantages of CRM for Businesses",
    description: "Explore how CRM can optimize processes, improve customer relationships, and increase your company's results.",
    coverImage: crm,
    content: [
      {
        order: 0,
        type: "header",
        content: "What is a CRM and why is it essential?"
      },
      {
        order: 1,
        type: "text",
        content: "CRM (Customer Relationship Management) is a strategy that centralizes customer interactions, helping companies offer more efficient and personalized service. More than just software, CRM combines processes, technology, and strategies aimed at retaining and attracting customers."
      },
      {
        order: 2,
        type: "imageFull",
        content: crmHawx,
      },
      {
        order: 3,
        type: "header",
        content: "What does it do?"
      },
      {
        order: 4,
        type: "text",
        content: "CRM integrates sales, marketing, and customer service into a single platform, facilitating access to information and enabling more efficient tracking of the customer journey. Additionally, the system optimizes processes, centralizes data, and improves the experience at every stage of customer relationships."
      },
      {
        order: 5,
        type: "header",
        content: "Main Advantages of CRM"
      },
      {
        order: 6,
        type: "image1/2",
        content: crm,
      },
      {
        order: 7,
        type: "list",
        content: [
          "Centralizes customer information and history, allowing quick and organized access to data.",
          "Automates operational processes, such as sending emails and follow-up reminders.",
          "Increases sales team productivity by reducing repetitive tasks.",
          "Facilitates tracking of metrics and performance with detailed reports.",
          "Helps retain customers by offering personalized and consistent service.",
          "Allows real-time tracking of each sales opportunity, prioritizing the most valuable leads.",
          "Strengthens customer relationships by managing the entire lifecycle, from the first contact to post-sale."
        ]
      },
      {
        order: 8,
        type: "header",
        content: "Impact on Customer Relationships"
      },
      {
        order: 9,
        type: "text",
        content: "With a CRM, your company can create a more connected and relevant experience for customers. The detailed interaction history helps understand each customer's needs and offer personalized solutions at the right time, strengthening bonds and increasing retention.\nSome CRMs allow integration with email and WhatsApp, optimizing communication and marketing. These tools help keep the brand present, enabling the sending of strategic messages that reinforce customer relationships. This allows efficient pre-sales, sales, and post-sales actions, aligning the entire experience on a single platform."
      },
      {
        order: 10,
        type: "header",
        content: "Increasing Operational Efficiency"
      },
      {
        order: 11,
        type: "text",
        content: "CRM automates routine tasks, such as sending follow-up emails, scheduling, and status updates. This reduces time spent on repetitive activities, allowing your team to focus on what really matters: closing deals."
      },
      {
        order: 12,
        type: "header",
        content: "Conclusion"
      },
      {
        order: 13,
        type: "text",
        content: "Investing in a CRM is a strategic step for any company looking to grow sustainably. With benefits ranging from internal organization to customer satisfaction, CRM is an indispensable tool in today's competitive market."
      }
    ],
    uploadDate: "2024-12-29T12:00:00Z",
    author: {
      name: "Eduardo Pedro Meneghel",
      photo: eduardomeneghel
    }
  },
  {
    id: "how-to-prevent-data-loss-using-notion",
    url: "/en/blog/how-to-prevent-data-loss-using-notion",
    title: "How to Prevent Data Loss, Facilitate Training, and Minimize the Impact of Employee Departures Using Notion",
    description: "Learn how to use Notion to organize information, optimize training, and handle employee exits without compromising your business efficiency.",
    coverImage: notion,
    content: [
      {
        order: 0,
        type: "header",
        content: "Introduction: The Importance of Efficient Knowledge Management"
      },
      {
        order: 1,
        type: "text",
        content: "Companies face challenges such as information loss, high turnover, and difficulties in training new employees. These problems can lead to high costs, process disruptions, and low productivity. Fortunately, tools like Notion can help overcome these barriers by centralizing data, standardizing training, and simplifying the transition of responsibilities."
      },
      {
        order: 2,
        type: "image1/2",
        content: notionLogo
      },
      {
        order: 3,
        type: "header",
        content: "1. Preventing Data Loss"
      },
      {
        order: 4,
        type: "text",
        content: "Data loss often occurs when information is scattered across multiple platforms or limited to the knowledge of a few employees. In Notion, you can create a unified system that stores important documents, procedure manuals, and critical data in an accessible way for the entire team."
      },
      {
        order: 5,
        type: "list",
        content: [
          "Organize a 'Knowledge Hub' with categorized links and documents.",
          "Create pages for each department, with subpages dedicated to projects, reports, and procedures.",
          "Implement databases with customizable fields to store contacts, contracts, and client records."
        ]
      },
      {
        order: 6,
        type: "header",
        content: "2. Facilitating Training and Onboarding"
      },
      {
        order: 7,
        type: "text",
        content: "Training new employees can be simplified with reusable templates and documented processes in Notion. This reduces the time needed to train new hires and improves consistency in learning."
      },
      {
        order: 8,
        type: "list",
        content: [
          "Create onboarding pages specific to each role, with detailed instructions.",
          "Add checklists to track training progress.",
          "Include videos, presentations, and useful links directly on training pages."
        ]
      },
      {
        order: 9,
        type: "header",
        content: "3. Handling Employee Departures"
      },
      {
        order: 10,
        type: "text",
        content: "The departure of an employee can negatively impact workflow, especially if knowledge is not properly transferred. In Notion, you can create transition systems that ensure crucial information is documented and shared with the team."
      },
      {
        order: 11,
        type: "list",
        content: [
          "Implement a knowledge transfer page with project details and contacts.",
          "Document workflows and critical processes to facilitate continuity.",
          "Use task assignment features to reorganize responsibilities during the transition."
        ]
      },
      {
        order: 12,
        type: "header",
        content: "Benefits of Using Notion in Business Management"
      },
      {
        order: 13,
        type: "text",
        content: "By using Notion, your company achieves more efficient management, with centralized data, documented processes, and optimized training. This not only reduces operational costs but also improves business resilience, allowing your team to quickly adapt to changes."
      },
      {
        order: 14,
        type: "header",
        content: "Conclusion"
      },
      {
        order: 15,
        type: "text",
        content: "Implementing Notion as a central tool for your company is a strategic decision. It allows you to prevent data loss, facilitate training, and minimize the impact of turnover. By creating organized and accessible systems, you transform knowledge management into a competitive advantage, ensuring business continuity and long-term success."
      }
    ],
    uploadDate: "2025-01-18T12:00:00Z",
    author: {
      name: "Eduardo Pedro Meneghel",
      photo: eduardomeneghel
    }
  },
  {
    id: "how-to-improve-your-relevance-on-google-my-business",
    url: "/en/blog/how-to-improve-your-relevance-on-google-my-business",
    title: "How to Improve Your Relevance on Google My Business and Attract More Customers",
    description: "Discover proven strategies to optimize your Google My Business profile, increase your visibility, and win more customers.",
    coverImage: logoGoogleMeuNegocio,
    content: [
      {
        order: 0,
        type: "header",
        content: "Why is Google My Business Essential for Your Company?"
      },
      {
        order: 1,
        type: "text",
        content: "Google My Business (GMB) is an indispensable tool for companies looking to increase their online visibility. It not only improves your presence in local search results but also strengthens your brand's credibility and attracts potential customers. A well-optimized profile can be the difference between being found or going unnoticed."
      },
      {
        order: 2,
        type: "image1/2",
        content: googleMeuNegocio,
      },
      {
        order: 3,
        type: "header",
        content: "1. Complete All Your Profile Information"
      },
      {
        order: 4,
        type: "text",
        content: "A complete profile is the first step to standing out. Make sure to include the following information:\n- Company name and area of expertise (example: Hawx Solutions - Software Development).\n- Full and updated address.\n- Phone and email for contact.\n- Business hours, including holidays and special hours.\n- Website and social media, if available."
      },
      {
        order: 5,
        type: "highlight",
        content: "Tip: Use relevant keywords in the company name and description to improve local SEO."
      },
      {
        order: 6,
        type: "header",
        content: "2. Add High-Quality Photos"
      },
      {
        order: 7,
        type: "text",
        content: "Professional photos increase your profile's attractiveness and create a good first impression. Include:\n- Storefront to facilitate location.\n- Interior and exterior environment.\n- Team to generate connection and trust.\n- Products and services offered."
      },
      {
        order: 8,
        type: "image1/2",
        content: fotoDeQualidade,
      },
      {
        order: 9,
        type: "header",
        content: "3. Register Products and Services"
      },
      {
        order: 10,
        type: "text",
        content: "The products and services feature on GMB helps customers understand what you offer. To maximize its impact:\n- Insert detailed descriptions with relevant keywords.\n- Add prices, if applicable.\n- Include attractive images for each item."
      },
      {
        order: 11,
        type: "header",
        content: "4. Use 360° Photos for an Immersive Experience"
      },
      {
        order: 12,
        type: "text",
        content: "360° photos offer a complete view of your space, increasing engagement and customer trust. This feature is ideal for:\n- Restaurants, gyms, and beauty salons.\n- Offices and coworking spaces.\n- Hotels and inns."
      },
      {
        order: 13,
        type: "imageFull",
        content: foto360,
      },
      {
        order: 14,
        type: "header",
        content: "5. Keep Your Profile Updated"
      },
      {
        order: 15,
        type: "text",
        content: "An outdated profile can drive customers away and harm your credibility. Practice:\n- Publishing posts with news, events, and offers.\n- Informing changes in business hours.\n- Regularly updating images and descriptions."
      },
      {
        order: 16,
        type: "header",
        content: "6. Offer Promotions and News"
      },
      {
        order: 17,
        type: "text",
        content: "Promotions are a great way to attract customers and increase engagement. Use GMB to:\n- Create exclusive discount coupons.\n- Announce product or service launches.\n- Promote special events and seasonal offers."
      },
      {
        order: 18,
        type: "header",
        content: "7. Encourage Reviews and Respond to All"
      },
      {
        order: 19,
        type: "text",
        content: "Positive reviews improve your ranking and credibility. To encourage them:\n- Ask for feedback from satisfied customers.\n- ⭐⭐⭐⭐⭐\n- Send direct links for reviews via WhatsApp or email.\n- Respond to all reviews, whether positive or negative."
      },
      {
        order: 20,
        type: "highlight",
        content: "Tip: Responding to reviews shows that you value customer feedback and care about their experience."
      },
      {
        order: 21,
        type: "header",
        content: "Conclusion"
      },
      {
        order: 22,
        type: "text",
        content: "Optimizing your Google My Business profile is an essential strategy for any company looking to increase its visibility and attract more customers. By following these tips, you not only improve your ranking but also create a positive experience for your customers, strengthening your brand and boosting your sales."
      }
    ],
    uploadDate: "2025-02-02T12:00:00Z",
    author: {
      name: "Eduardo Pedro Meneghel",
      photo: eduardomeneghel
    }
  }
]