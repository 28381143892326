import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import logo from '../../assets/logo-white.webp';
import brFlag from '../../assets/br-flag.webp';
import usFlag from '../../assets/us-flag.webp';
import esFlag from '../../assets/es-flag.webp';

const Header = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  
  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const scrollToServices = () => {
    const servicesSection = document.getElementById("services");
    if (servicesSection) {
      servicesSection.scrollIntoView({ behavior: "smooth" });
    } else {
      console.warn("No se encontró la sección de servicios.");
    }
  };

  useEffect(() => {
    if (window.location.hash === "#services") {
      scrollToServices();
    }
  }, []);

  return (
    <header className="block px-8 bg-pattern z-50 w-full absolute">
      <nav className="w-11/12 mx-auto py-6 flex justify-between items-center">
        <div>
          <a href="https://hawx.com.br/es" className="inline-block">
            <img src={logo} alt="Logo de un pájaro blanco, representando un halcón peregrino con alas extendidas, en estilo minimalista, sobre un fondo circular azul. Junto al logo está escrito 'Hawx', y debajo la palabra 'Soluciones'." className="w-32 xl:w-44" />
          </a>
        </div>
        <div className="md:hidden">
          <button
            className="text-white hover:text-black focus:outline-none p-2"
            onClick={toggleMobileMenu}
            aria-label={isMobileMenuOpen ? "Cerrar menú" : "Abrir menú"}
          >
            {isMobileMenuOpen ? (
              <FontAwesomeIcon icon={faTimes} className="text-xl" />
            ) : (
              <FontAwesomeIcon icon={faBars} className="text-xl" />
            )}
          </button>
        </div>
        <ul className="hidden md:flex space-x-4 items-center">
          <li>
            <a href="https://hawx.com.br/es" className="text-white hover:text-black font-semibold">
              Inicio
            </a>
          </li>
          <li>
            <a
              href="https://hawx.com.br/es/#services"
              className="text-white hover:text-black font-semibold"
            >
              Servicios
            </a>
          </li>
          <li>
            <a href="https://hawx.com.br/es/productos" className="text-white hover:text-black font-semibold">
              Productos
            </a>
          </li>
          <li>
            <a href="https://hawx.com.br/es/blog" className="text-white hover:text-black font-semibold">
              Blog
            </a>
          </li>
          <li>
            <a href="https://hawx.com.br/es/sobre-nosotros" className="text-white hover:text-black font-semibold">
              Sobre nosotros
            </a>
          </li>
          <li>
            <a
              href="https://api.whatsapp.com/send?phone=5548999631298"
              target="_blank"
              rel="noopener noreferrer"
              className="text-white hover:text-black font-semibold"
            >
              Contacto
            </a>
          </li>
          <li className="flex space-x-2">
            <a href="https://hawx.com.br/" aria-label="Portugués">
              <img src={brFlag} alt="Bandera de Brasil" className="w-6 h-6" />
            </a>
            <a href="https://hawx.com.br/en" aria-label="English">
              <img src={usFlag} alt="Bandera de los EE. UU." className="w-6 h-6" />
            </a>
            <a href="https://hawx.com.br/es" aria-label="Español">
              <img src={esFlag} alt="Bandera de España" className="w-6 h-6" />
            </a>
          </li>
        </ul>
        <div
          className={`fixed inset-0 bg-pattern z-40 transform ${isMobileMenuOpen ? "translate-x-0" : "translate-x-full"
            } transition-transform duration-300 ease-in-out`}
        >
          <button
            className="absolute top-6 right-6 text-white text-3xl focus:outline-none"
            onClick={toggleMobileMenu}
            aria-label="Cerrar menú"
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>

          <ul className="flex flex-col items-center justify-center h-screen">
            <li className="mb-8">
              <a
                href="https://hawx.com.br/es"
                className="text-white text-2xl hover:text-black block"
              >
                Inicio
              </a>
            </li>
            <li className="mb-8">
              <a
                href="https://hawx.com.br/es/#services"
                className="text-white text-2xl hover:text-black block"
                onClick={toggleMobileMenu}
              >
                Servicios
              </a>
            </li>
            <li className="mb-8">
              <a
                href="https://hawx.com.br/es/productos"
                className="text-white text-2xl hover:text-black block"
              >
                Productos
              </a>
            </li>
            <li className="mb-8">
              <a
                href="https://hawx.com.br/es/blog"
                className="text-white text-2xl hover:text-black block"
              >
                Blog
              </a>
            </li>
            <li className="mb-8">
              <a
                href="https://hawx.com.br/es/sobre-nos"
                className="text-white text-2xl hover:text-black block"
              >
                Sobre nosotros
              </a>
            </li>
            <li className="mb-8">
              <a
                href="https://api.whatsapp.com/send?phone=5548999631298"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white text-2xl hover:text-black block"
              >
                Contacto
              </a>
            </li>
            <li className="mb-8 flex space-x-2">
              <a href="https://hawx.com.br/" aria-label="Portugués">
                <img src={brFlag} alt="Bandera de Brasil" className="w-6 h-6" />
              </a>
              <a href="https://hawx.com.br/en" aria-label="English">
                <img src={usFlag} alt="Bandera de los EE. UU." className="w-6 h-6" />
              </a>
              <a href="https://hawx.com.br/es" aria-label="Español">
                <img src={esFlag} alt="Bandera de España" className="w-6 h-6" />
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
};

export default Header;