import React from 'react';

import Design from './layout/Design';
import Platform from './layout/Platform';

const Layout = () => {
  return (
    <>
      <div className="z-40">
        <div className="text-black w-3/4 mx-auto">
          <p className="xl:text-6xl text-4xl font-medium xl:text-center xl:pt-40 pt-16 xl:pb-32 pb-8 xl:pb-16">We adapt to your needs</p>
          <Platform/>
          <Design/>
        </div>
      </div>
    </>
  );
};

export default Layout;