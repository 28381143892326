import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import './css/index.css';
import './css/tailwind-output.css';

// Importando as páginas de cada idioma
import HomePt from "./pages/ptBr/Home";
import BlogPostPt from "./pages/ptBr/BlogPost";
import BlogPt from "./pages/ptBr/Blog";
import ProductsPt from "./pages/ptBr/Products";
import AboutUsPt from "./pages/ptBr/AboutUs";
import NotFoundPt from "./pages/ptBr/NotFound";

import HomeEn from "./pages/en/Home";
import BlogPostEn from "./pages/en/BlogPost";
import BlogEn from "./pages/en/Blog";
import ProductsEn from "./pages/en/Products";
import AboutUsEn from "./pages/en/AboutUs";
import NotFoundEn from "./pages/en/NotFound";

import HomeEs from "./pages/es/Home";
import BlogPostEs from "./pages/es/BlogPost";
import BlogEs from "./pages/es/Blog";
import ProductsEs from "./pages/es/Products";
import AboutUsEs from "./pages/es/AboutUs";
import NotFoundEs from "./pages/es/NotFound";

createRoot(document.getElementById("root")).render(
  <Router>
    <Routes>
      {/* Rotas em português */}
      <Route path="/" element={<HomePt />} />
      <Route path="/blog/:id" element={<BlogPostPt />} />
      <Route path="/blog" element={<BlogPt />} />
      <Route path="/produtos" element={<ProductsPt />} />
      <Route path="/sobre-nos" element={<AboutUsPt />} />

      {/* Rotas em inglês */}
      <Route path="/en/" element={<HomeEn />} />
      <Route path="/en/blog/:id" element={<BlogPostEn />} />
      <Route path="/en/blog" element={<BlogEn />} />
      <Route path="/en/products" element={<ProductsEn />} />
      <Route path="/en/about-us" element={<AboutUsEn />} />

      {/* Rotas em espanhol */}
      <Route path="/es/" element={<HomeEs />} />
      <Route path="/es/blog/:id" element={<BlogPostEs />} />
      <Route path="/es/blog" element={<BlogEs />} />
      <Route path="/es/productos" element={<ProductsEs />} />
      <Route path="/es/sobre-nosotros" element={<AboutUsEs />} />

      {/* Página 404 */}
      <Route path="*" element={<NotFoundPt />} />
    </Routes>
  </Router>
);