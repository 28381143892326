import React from "react";

const About = () => {
  return (
    <div className="bg-pattern xl:pt-48 py-16 overflow-hidden">
      <div className="text-black w-3/4 mx-auto">
        <h1 className="xl:text-6xl text-3xl font-bold text-white text-center" aria-label="About us">
          About us
        </h1>
        <div className="mt-8 xl:mt-16 mb-16">
          <p className="text-2xl text-white text-justify" aria-label="Description about Eduardo and Hawx Solutions">
            I am Eduardo, a graduate in Information Systems and the proud founder of Hawx Solutions. With a deep interest in entrepreneurship from the beginning of my career, my company represents the result of this desire for innovation and excellence in technological solutions. We are committed to providing high-quality services and customized solutions to meet the needs of our clients.
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
