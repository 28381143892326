import React, { useState } from 'react';
import image1 from "../../../assets/1.webp";

const Logotipo = () => {
  return (
    <>
      <div className="flex flex-col-reverse xl:flex-row xl:pt-24 pb-12 mb-4">
        <div className="flex xl2:w-1/2 xl:w-10/12 z-10 xl:block justify-center xl:justify-end animation-image">
           <img src={image1} alt="Floating mobile phone with a circular blue background. On the phone screen, a website is displayed." />
        </div>
        <div className="xl:w-1/2 z-10">
          <p className="text-xl font-medium mb-4 text-green-700">Website</p>
          <p className="text-3xl xl:text-6xl mb-10 font-medium">Promote your business online</p>
          <p className="text-2xl mt-12 mb-16">Follow the lead of other companies—have your own website and attract new customers.</p>
        </div>
      </div>
    </>
  );
};

export default Logotipo;
