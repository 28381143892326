import React from "react";
import Header from "../../components/es/Header";
import Budget from "../../components/es/Budget";
import Services from "../../components/es/Services";
import Layout from "../../components/es/Layout";
import Footer from "../../components/es/Footer";
import Whatsapp from "../../components/es/util/Whatsapp";

const Home = () => {
  return (
    <>
      <Header />
      <Budget />
      <Services />
      <Layout />
      <Whatsapp />
      <Footer />
    </>
  );
};

export default Home;
