import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faUser, faMessage } from '@fortawesome/free-solid-svg-icons';
import logo from '../../assets/logo-white.webp';

function Footer() {
  return (
    <footer className="bg-pattern">
      <div className="w-3/4 mx-auto py-12 text-2xl">
        <a href={"../"}>
          <img src={logo} alt="Logo of a white bird, representing a peregrine falcon with open wings, in a minimalist style, on a circular blue background. To the right of the logo, 'Hawx' is written, and below, the word 'Solutions'." className="mx-auto mb-4 w-64" />
        </a>
        <div className="container mx-auto grid gap-4 grid-cols-1 md:grid-cols-3 mt-8">
          <div className="flex flex-col mb-4 md:mb-0">
            <p className="text-1xl font-bold text-white text-center mb-4">
              Contact
            </p>
            <p className="text-sm text-center text-white">
              <a href="https://api.whatsapp.com/send?phone=5548999631298" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faWhatsapp} className="pt-2 pr-2" />
                48999631298
              </a>
            </p>
            <p className="text-sm text-center text-white">
              <a href="mailto:contato@hawx.com.br">
                <FontAwesomeIcon icon={faEnvelope} className="pt-2 pr-2" />
                contact@hawx.com.br
              </a>
            </p>
          </div>
          <div className="flex flex-col mb-4 md:mb-0">
            <p className="text-1xl font-bold text-center mb-4 text-white">
              Social Media
            </p>
            <div className="flex justify-center">
              <p className="text-sm text-center text-white">
                <a href="https://www.instagram.com/hawxsolucoes/" target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faInstagram} className="pt-2 pr-2" />
                  Instagram
                </a>
              </p>
            </div>
          </div>
          <div className="flex flex-col">
            <p className="text-1xl font-bold text-center mb-4 text-white">
              About
            </p>
            <p className="text-sm text-center text-white">
              <a href={"/en/about-us"}>
                <FontAwesomeIcon icon={faUser} className="pt-2 pr-2" />
                About Us
              </a>
            </p>
            <p className="text-sm text-center text-white">
              <a href="https://api.whatsapp.com/send?phone=5548999631298" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faMessage} className="pt-2 pr-2" />
                Contact Us
              </a>
            </p>
          </div>
        </div>
        <div className="container mx-auto mt-4 text-sm text-center">
          <p className=' text-white'>CNPJ: 55.357.499/0001-80</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
