import React from "react";
import Header from "../../components/es/Header";
import Footer from "../../components/es/Footer";
import Whatsapp from "../../components/es/util/Whatsapp";
import BlogGenerator from "../../components/es/BlogGenerator";
import { blogPosts } from "../../components/es/data/blogPosts";

function BlogPost() {
  return (
    <>
      <Header />
      <BlogGenerator blogPosts={blogPosts} />
      <Whatsapp />
      <Footer />
    </>
  );
}

export default BlogPost;
