import React from "react";

const Values = () => {
  return (
    <>
      <div className="bg-gray-50">
        <div className="text-black w-3/4 mx-auto xl:py-32 py-16">
          <p className="xl:text-6xl text-4xl font-bold mb-12 text-center">Nuestros Valores</p>
          <div className="grid gap-16 text-2xl">
            <div>
              <p className="font-bold text-3xl">Calidad</p>
              <p className="mt-4 text-justify">
                Buscamos la excelencia en cada detalle, creando experiencias visualmente atractivas, intuitivas y funcionales. Nuestra dedicación está en ofrecer soluciones confiables, seguras y siempre disponibles, garantizando que la integridad de los datos se preserve y que el rendimiento nunca se vea comprometido.
              </p>
            </div>
            <div>
              <p className="font-bold text-3xl">Honestidad</p>
              <p className="mt-4 text-justify">
                Actuamos con transparencia y sinceridad en todas nuestras interacciones. Nos comunicamos de manera clara y abierta, priorizando la confianza mutua y la credibilidad en todas nuestras relaciones.
              </p>
            </div>
            <div>
              <p className="font-bold text-3xl">Aprendizaje Continuo</p>
              <p className="mt-4 text-justify">
                Estamos en constante evolución, siempre buscando mejoras y nuevas formas de innovar para servir mejor a nuestros clientes y adaptarnos a los cambios del mercado.
              </p>
            </div>
            <div>
              <p className="font-bold text-3xl">Éxito del Cliente</p>
              <p className="mt-4 text-justify">
                El éxito de nuestros clientes es nuestro éxito. Nos enfocamos en comprender sus necesidades, superar sus expectativas y brindar un soporte continuo para que alcancen sus objetivos con excelencia.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Values;