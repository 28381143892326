import React from "react";
import { Link } from "react-router-dom";
import Header from "../../components/ptBr/Header";
import Footer from "../../components/ptBr/Footer";
import Whatsapp from "../../components/ptBr/util/Whatsapp";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import crm1 from '../../assets/crm1.webp';
import crm2 from '../../assets/crm2.webp';
import crm3 from '../../assets/crm3.webp';
import crm4 from '../../assets/crm4.webp';
import crm5 from '../../assets/crm5.webp';
import crm6 from '../../assets/crm6.webp';

const products = [
  {
    id: 1,
    name: "CRM empresarial",
    description: "Nosso CRM automatiza processos, organiza dados e acompanha oportunidades para você vender mais com menos esforço. Com análise de dados e um relacionamento mais próximo, suas decisões se tornam mais estratégicas e eficazes.",
    price: "R$ 49,90/mês",
    images: [crm1, crm2, crm3, crm4, crm5, crm6],
    whatsappLink: "https://wa.me/5548999631298?text=Ol%C3%A1%2C%20quero%20saber%20mais%20sobre%20o%20CRM%20Profissional!"
  }
];

function Products() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000
  };

  return (
    <>
      <Header />
      <section className="bg-gray-100 pt-24">
        <div className="max-w-7xl mx-auto py-12 px-8">
          <h1 className="text-4xl font-bold lg:text-center" id="products">Conheça Nossos Produtos</h1>
          <p className="text-lg text-gray-600 mt-4 lg:text-center">Soluções inovadoras para ajudar sua empresa a crescer!</p>
          <p className="text-lg text-gray-600 mt-4 lg:text-center">
            Precisa de uma solução personalizada para sua empresa? <Link to="/#services" className="text-green-700 hover:underline">Conheça nossos serviços.</Link>
          </p>
        </div>
      </section>
      <div className="max-w-7xl mx-auto py-16 px-6">
        {products.map((product) => (
          <div key={product.id} className="flex flex-col md:flex-row bg-white border rounded-lg overflow-hidden p-6 mb-10">
            <div className="w-full md:w-1/3">
              <Slider {...settings}>
                {product.images.map((image, index) => (
                  <div key={index}>
                    <img
                      src={image}
                      alt={`${product.name} - Imagem ${index + 1}`}
                      className="w-full object-cover rounded-lg"
                    />
                  </div>
                ))}
              </Slider>
            </div>
            <div className="md:ml-6 mt-4 md:mt-0 text-left">
              <h2 className="text-2xl font-semibold mb-2">{product.name}</h2>
              <p className="text-gray-600 mb-4">{product.description}</p>
              <p className="text-lg font-bold text-green-700">{product.price}</p>
              <a
                href={product.whatsappLink}
                className="inline-block mt-4 bg-green-700 text-white px-6 py-3 rounded-lg shadow hover:bg-green-800 transition"
                target="_blank"
              >
                Fale Conosco no WhatsApp
              </a>
            </div>
          </div>
        ))}
      </div>
      <Whatsapp />
      <Footer />
    </>
  );
}

export default Products;