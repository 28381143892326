import React, { useState } from 'react';
import Svg from "../util/Svg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import image5 from "../../../assets/5.webp";
import Tooltip from '../util/Tooltip';

const Design = () => {
  return (
    <>
      <div className="flex flex-col-reverse xl:flex-row xl:pt-24 pb-12 mb-16">
        <div className="flex xl2:w-1/2 xl:w-10/12 z-10 xl:block justify-center xl:justify-end animation-image">
           <img src={image5} alt="Mobile phone lying down, floating with a triangular blue background. On the phone screen, a unique and responsive design is displayed." />
        </div>
        <div className="w-full xl:w-1/2 ">
          <p className="text-xl font-medium mb-4 text-green-700">Design</p>
          <p className="text-3xl xl:text-6xl mb-10 font-medium">Get a unique and responsive design</p>
          <div className="flex ">
            <Svg name="check" fill="green" className="mt-1 mr-6" />
            <p className="text-lg">Design system</p>
          </div>
          <div className="flex ">
            <Svg name="check" fill="green" className="mt-1 mr-6" />
            <p className="text-lg block">Enhanced adjustments</p>
            <Tooltip mensagem="During development">
              <FontAwesomeIcon icon={faCircleInfo} className="text-lg mt-1 ml-2 text-black" />
            </Tooltip>
          </div>
          <div className="flex mb-8 xl:mb-16">
            <Svg name="check" fill="green" className="mt-1 mr-6" />
            <p className="text-lg">Responsiveness</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Design;
