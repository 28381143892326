import React from "react";
import Header from "../../components/en/Header";
import Budget from "../../components/en/Budget";
import Services from "../../components/en/Services";
import Layout from "../../components/en/Layout";
import Footer from "../../components/en/Footer";
import Whatsapp from "../../components/en/util/Whatsapp";

const Home = () => {
  return (
    <>
      <Header />
      <Budget />
      <Services />
      <Layout />
      <Whatsapp />
      <Footer />
    </>
  );
};

export default Home;
