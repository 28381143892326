import React from "react";

const Vision = () => {
  return (
    <>
      <div className="bg-pattern flex items-center xl:py-32 py-16">
        <div className="text-black w-3/4 mx-auto">
          <p className="xl:text-6xl text-4xl font-bold text-white text-center">Vision</p>
          <p className="text-2xl mt-12 text-justify text-white">
            Our vision at Hawx Solutions is to become recognized leaders in the technology sector, being the first choice for companies seeking innovative and reliable digital solutions. We aim to expand our global presence while maintaining our commitment to excellence, creativity, and customer satisfaction.
          </p>
        </div>
      </div>
    </>
  );
};

export default Vision;
