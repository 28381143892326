import React from 'react';
import '../../../css/Tooltip.css';

function Tooltip({ mensagem, children }) {
  return (
    <div className="tooltip">
      {children}
      <span className="tooltiptext">{mensagem}</span>
    </div>
  );
}

export default Tooltip;
