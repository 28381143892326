import React from "react";
import responsive from "../../assets/responsive.webp";

const Quote = () => {
  return (
    <>
      <div className="bg-pattern-gradient xl:pb-32">
        <div className="text-white w-3/4 mx-auto bg-cover bg-center">
          <div className="flex flex-col xl:flex-row xl:pt-48 pt-24 pb-16">
            <div className="xl2:w-1/2 xl:w-10/12 z-10 align-center">
              <img src={responsive} alt="Mobile phone, laptop, and mug placed side by side. The mug features the Hawx Solutions logo, with a minimalist white bird and the text 'Hawx Solutions'. The screens of the phone and laptop display the responsive interface of the Hawx Solutions CRM system." className="h-auto mr-auto" />
            </div>
            <div className="xl:w-1/2 w-full z-10">
              <p className="xl:text-7xl text-5xl font-medium">Request your <strong className="text-gradient">quote</strong> now</p>
              <p className="text-2xl mt-12 mb-9 text-justify">We offer high-quality, customized solutions to meet your needs with excellence.</p>
              <a href="https://api.whatsapp.com/send?phone=5548999631298" target="_blank" className="bg-opaque text-white py-6 px-8 rounded-2xl transition mt-8 w-full xl:w-2/3 block text-xl text-center">
                Request your quote
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Quote;
