import React from "react";
import Header from "../../components/ptBr/Header";
import Footer from "../../components/ptBr/Footer";
import Whatsapp from "../../components/ptBr/util/Whatsapp";
import BlogGenerator from "../../components/ptBr/BlogGenerator";
import { blogPosts } from "../../components/ptBr/data/blogPosts";

function BlogPost() {
  return (
    <>
      <Header />
      <BlogGenerator blogPosts={blogPosts} />
      <Whatsapp />
      <Footer />
    </>
  );
}

export default BlogPost;
