import React from "react";

const Values = () => {
  return (
    <>
      <div className="bg-gray-50">
        <div className="text-black w-3/4 mx-auto xl:py-32 py-16">
          <p className="xl:text-6xl text-4xl font-bold mb-12 text-center">Our Values</p>
          <div className="grid gap-16 text-2xl">
            <div>
              <p className="font-bold text-3xl">Quality</p>
              <p className="mt-4 text-justify">
                We strive for excellence in every detail, creating visually appealing, intuitive, and functional experiences. Our dedication is to deliver reliable, secure, and always available solutions, ensuring data integrity is preserved and performance is never compromised.
              </p>
            </div>
            <div>
              <p className="font-bold text-3xl">Honesty</p>
              <p className="mt-4 text-justify">
                We act with transparency and sincerity in all our interactions. We communicate clearly and openly, prioritizing mutual trust and credibility in all our relationships.
              </p>
            </div>
            <div>
              <p className="font-bold text-3xl">Continuous Learning</p>
              <p className="mt-4 text-justify">
                We are constantly evolving, always seeking improvements and new ways to innovate to better serve our clients and adapt to market changes.
              </p>
            </div>
            <div>
              <p className="font-bold text-3xl">Customer Success</p>
              <p className="mt-4 text-justify">
                Our customers' success is our success. We focus on understanding their needs, exceeding their expectations, and providing continuous support to help them achieve their goals with excellence.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Values;