import React from "react";

const Mission = () => {
  return (
    <>
      <div className="bg-gray-50 flex items-center xl:py-32 py-16">
        <div className="text-black w-3/4 mx-auto">
          <p className="xl:text-6xl text-4xl font-bold text-center">Misión</p>
          <p className="text-2xl mt-12 text-justify">
            Nuestra misión en Hawx Soluciones es transformar ideas en realidad digital a través de soluciones tecnológicas innovadoras y a medida. Estamos comprometidos en comprender profundamente las necesidades de nuestros clientes y ofrecer productos y servicios de alta calidad que impulsen sus negocios al éxito. Buscamos constantemente superar fronteras, aprender y evolucionar, creando un impacto positivo y duradero en el mundo digital.
          </p>
        </div>
      </div>
    </>
  );
};

export default Mission;
